import { useEffect } from "react";
import router, { useRouter } from "next/router";
import {
  COMPANY_STATUS_ENUM,
  MENU_ITEMS_RESTRICTION,
  PATHNAME_NOT_RESTRICTED,
} from "@constants";
import { IMPERSONATION } from "../constants/localStorageKeys";

import { useSelector } from "react-redux";
const mobileDeepLinkKeys = {
  companyProfile: "company-profile=",
  produce: "produce=",
  productSearch: "product-search=",
  productDemandSearch: "product-demand-search=",
  directorySearch: "directory-search=",
  orderDetail: "order-detail=",
  post: "post-share=",
};

const mobileDeepLinkRoutes = {
  postShare: "/post-share",
  offerRefCode: "/login",
  produce: "/produce-explore/[id]",
  companyProfile: "/c/[id]",
  mycompanyProduct: "/my-company/detail-produce",
  produceExplore: "/produce-explore",
  productDemandSearch: "/demanded-products",
  directorySearch: "/company-directory",
  orderDetail: "/purchases/orders-placed/[id]",
};

async function sendMobileApp({
  router,
  props,
  impersonation,
  PrivateComponent,
}) {
  if (router.pathname === mobileDeepLinkRoutes.produce) {
    const keyword = "produce=" + router.query.id;
    router.push("/redirect-app?" + keyword);
  } else if (router.pathname === mobileDeepLinkRoutes.companyProfile) {
    const keyword = mobileDeepLinkKeys.companyProfile + router.query?.id;
    router.push("/redirect-app?" + keyword);
  } else if (router.pathname === mobileDeepLinkRoutes.mycompanyProduct) {
    const keyword = mobileDeepLinkKeys.produce + router.query?.produceId;
    router.push("/redirect-app?" + keyword);
  } else if (router.pathname === mobileDeepLinkRoutes.produceExplore) {
    const keyword = mobileDeepLinkKeys.productSearch + router.query?.keyword;
    router.push("/redirect-app?" + keyword);
  } else if (router.pathname === mobileDeepLinkRoutes.productDemandSearch) {
    const keyword =
      mobileDeepLinkKeys.productDemandSearch + router.query?.keyword;
    router.push("/redirect-app?" + keyword);
  } else if (router.pathname === mobileDeepLinkRoutes.directorySearch) {
    const keyword = mobileDeepLinkKeys.directorySearch + router.query?.keyword;
    router.push("/redirect-app?" + keyword);
  } else if (router.pathname === mobileDeepLinkRoutes.orderDetail) {
    const keyword = mobileDeepLinkKeys.orderDetail + props?.router?.query.id;
    router.push("/redirect-app?" + keyword);
  } else if (router.pathname === mobileDeepLinkRoutes.postShare) {
    const keyword = mobileDeepLinkKeys.post + props?.router?.query.id;
    router.push("/redirect-app?" + keyword);
  }

  return <PrivateComponent {...{ ...props, impersonation }} />;
}

export default function withPrivate(
  PrivateComponent,
  getExtendProps = (props) => ({
    ...props,
  })
) {
  const withThing = (props) => {
    const router = useRouter();
    const user = useSelector((state) => state.auth.user);
    const company = useSelector((state) => state.auth.company);
    const isPathnameRestricted = (pathname) =>
      !PATHNAME_NOT_RESTRICTED.includes(pathname);
    const impersonation = localStorage.getItem(IMPERSONATION);

    if (router?.pathname === "/surplus-allocation" && user) {
      useEffect(() => {
        if (company?.id) {
          const getCompanyInfo = async () => {
            if (!company?.canAllocateSurplus) {
              router?.push("/");
            }
          };
          getCompanyInfo();
        }
      }, []);
    }

    useEffect(() => {
      const isMobile = window.innerWidth < 750;

      if (isMobile) {
        return sendMobileApp({
          router,
          props,
          impersonation,
          PrivateComponent,
        });
      }

      if (!props.valid) {
        router.push("/login");
      }

      // If user haven't company
      else if (
        props.isNotBussinessUser &&
        isPathnameRestricted(router.pathname)
      ) {
        router.push("/company-registration");
      }
      // If account is restriction account and current screen is restricted -> redirect to profile screen.
      else if (
        props.isAdminAccountRestricted &&
        isPathnameRestricted(router.pathname)
      ) {
        router.push("/my-company-profile");
      }
    }, []);

    if (
      (props.isAdminAccountRestricted || props.isNotBussinessUser) &&
      isPathnameRestricted(router.pathname)
    )
      return null;

    if (props.valid)
      return <PrivateComponent {...{ ...props, impersonation }} />;
    return null;
  };

  withThing.getInitialProps = async (ctx) => {
    try {
      const { req, res, store } = ctx;
      let valid = false;
      let isAdminAccountRestricted = false;
      let isNotBussinessUser = false;

      const {
        auth: { user },
      } = store.getState();
      if (user) {
        valid = true;
        // Check user is an admin of the company that was not approved
        const isNotApprovedCompanyAdmin =
          user?.type === "COMPANY_ADMIN" &&
          user?.companyStatus === COMPANY_STATUS_ENUM.PENDING &&
          user?.companyId;
        // Check user is an admin of the company that was not created
        const isNotCreatedCompanyAdmin =
          user?.type === "COMPANY_ADMIN" && !user?.companyId;
        // Check user is not an employee of any company
        isNotBussinessUser = !user?.companyId;
        if (isNotApprovedCompanyAdmin || isNotCreatedCompanyAdmin)
          isAdminAccountRestricted = true;
      } else {
        // server only
        if (req && res) {
          res.writeHead(307, { Location: router.asPath });
          res.end();
        }
        return { valid: false };
      }
      return getExtendProps({
        valid,
        isAdminAccountRestricted,
        isNotBussinessUser,
        isPrivate: true,
      });
    } catch (err) {
      return {};
    }
  };

  return withThing;
}
